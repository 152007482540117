const Cookies = require('js-cookie');

jQuery(document).ready(function () {	
	const urlParams = new URLSearchParams(window.location.search);
	const campaign_medium_param = urlParams.get('utm_medium');
	const campaign_code_param = urlParams.get('utm_campaign');
	if ( ! campaign_code_param ) return;
	if ( 'influencer' !== campaign_medium_param ) return;

	const affiliates = Object.values( jrcYfaAff.affiliates );

	affiliates.forEach(element => {
		const code = element.campaign_code.toLowerCase();
		const today = new Date();
		let expiration = new Date(today.getTime() +1000*60*60*24*365*2); // two years
		if ( element.end_date ) {
			expiration = new Date(new Date(element.end_date).getTime());
		}
		
		const cookieString = `yfa_aff_${code}`.toLowerCase();
		if ( code ===  campaign_code_param.toLowerCase() ) {
			const exists = Cookies.get(cookieString);
			Cookies.set( cookieString, true, { expires: expiration });
		}
	});

});